import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavbarDropdown } from "./navbar-dropdown";

type props = {};

export const Navbar: React.FunctionComponent<props> = ({}) => {
  const navigate = useNavigate();
  const [responsive, setResponsive] = useState(false);
  const [offset, setOffset] = useState(0);
  const [showDropdown, setShowDropdown] = useState<string[]>([]);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (responsive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  });

  const handleDropdown = (dropdown: string) => {
    setShowDropdown((prevState) => {
      if (prevState.includes(dropdown)) {
        const clone = [...prevState];
        clone.splice(prevState.indexOf(dropdown), 1);
        return clone;
      } else {
        return [...prevState, dropdown];
      }
    });
  };

  return (
    <nav
      className={
        offset > 65 && !responsive
          ? "nav nav-responsive"
          : responsive
          ? "theme-bg-color-5 nav"
          : "nav"
      }
    >
      <div className="nav-container">
        {offset > 65 && !responsive ? (
          <div className="logo logo-responsive">
            <img
              className="w-full"
              src={"/logo.png"}
              alt=""
              onClick={() => navigate("/")}
            />
          </div>
        ) : (
          <div className="logo">
            <img
              className="w-full"
              src={"/alt-logo.png"}
              alt=""
              onClick={() => navigate("/")}
            />
          </div>
        )}

        <div
          className={
            responsive
              ? "nav-primary-list show-list"
              : offset > 65
              ? "nav-primary-list scroll-down-hide-list"
              : "nav-primary-list scroll-up-hide-list"
          }
        >
          <ul>
            <li>
              <a
                className={
                  offset > 65 && !responsive ? "forced-theme-text-color-1" : ""
                }
                href="/getting-started"
              >
                Getting Started
              </a>
            </li>
            <NavbarDropdown
              label="Forms"
              labelClassName={
                offset > 65 && !responsive ? "forced-theme-text-color-1" : ""
              }
              text1="Adults"
              url1="/forms-adult"
              text2="Teens"
              url2="/forms-teen"
              text3="Child"
              url3="/forms-child"
              onClick={() => handleDropdown("1")}
              showDropdown={showDropdown.includes("1") ? true : false}
            />
            <li>
              <a
                className={
                  offset > 65 && !responsive ? "forced-theme-text-color-1" : ""
                }
                href="/faq"
              >
                Faq
              </a>
            </li>
          </ul>
        </div>
        <span
          className={responsive ? "nav-trigger active" : "nav-trigger"}
          onClick={() => setResponsive(!responsive)}
        >
          <i
            className={
              offset > 65 && !responsive ? "forced-theme-bg-color-1" : ""
            }
          ></i>
          <i
            className={
              offset > 65 && !responsive ? "forced-theme-bg-color-1" : ""
            }
          ></i>
        </span>
      </div>
    </nav>
  );
};
