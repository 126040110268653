export const CONTENTFUL_GET_ENTRY_RESPONSE = "CONTENTFUL_GET_ENTRY_RESPONSE";

export type ContentfulGetEntryResponse = {
  type: typeof CONTENTFUL_GET_ENTRY_RESPONSE;
  data: ContentfulFields;
};

export type ContentfulFields = {
  fields: ContentfulContent;
};

export type ContentfulContent = {
  customHeader1: ContentfulCustomText;
  customHeader2: ContentfulCustomText;
  customHeader3: ContentfulCustomText;
  customHeader4: ContentfulCustomText;
  customHeader5: ContentfulCustomText;
  customHeader6: ContentfulCustomText;
  customHeader7: ContentfulCustomText;
  customHeader8: ContentfulCustomText;
  customHeader9: ContentfulCustomText;
  customHeader10: ContentfulCustomText;
  customText1: ContentfulCustomText;
  customText2: ContentfulCustomText;
  customText3: ContentfulCustomText;
  customText4: ContentfulCustomText;
  customText5: ContentfulCustomText;
  customText6: ContentfulCustomText;
  customText7: ContentfulCustomText;
  customText8: ContentfulCustomText;
  customText9: ContentfulCustomText;
  customText10: ContentfulCustomText;
  customHomeContentBox1: ContentfulCustomHomeContentBox;
  customHomeContentBox2: ContentfulCustomHomeContentBox;
  customHomeContentBox3: ContentfulCustomHomeContentBox;
  customHomeContentBox4: ContentfulCustomHomeContentBox;
};

export type ContentfulCustomText = {
  alignment1: string;
  line1: string;
  alignment2: string;
  line2: string;
};

export type ContentfulCustomHomeContentBox = {
  header: string;
  text: string;
  buttonLabel: string;
}