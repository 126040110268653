import { CaretDownFill } from "@styled-icons/bootstrap/CaretDownFill";

type props = {
  label?: string;
  labelClassName?: string;
  text1?: string;
  url1?: string;
  text2?: string;
  url2?: string;
  text3?: string;
  url3?: string;
  onClick: Function;
  showDropdown: boolean;
};

export const NavbarDropdown: React.FunctionComponent<props> = ({
  label,
  labelClassName,
  text1,
  url1,
  text2,
  url2,
  text3,
  url3,
  onClick,
  showDropdown,
}) => {
  return (
    <div className="dropdown">
      <li>
        <button
          className={
            labelClassName ? `${labelClassName} dropdown-text` : "dropdown-text"
          }
          onClick={() => onClick()}
        >
          <CaretDownFill
            className={
              labelClassName
                ? `${labelClassName} navbar-caret-down-fill`
                : "navbar-caret-down-fill"
            }
          />
          {label}
        </button>
      </li>
      <div
        className={
          showDropdown
            ? "dropdown-content-show dropdown-content"
            : "dropdown-content"
        }
      >
        {text1 && url1 && <a href={url1}>{text1}</a>}
        {text2 && url2 && <a href={url2}>{text2}</a>}
        {text3 && url3 && <a href={url3}>{text3}</a>}
      </div>
    </div>
  );
};
