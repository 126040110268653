import { useEffect, useState } from "react";
import { useApi } from "../../api";
import { FAQ_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";
import { CustomHeader } from "../../components/custom-header";
import { CustomText } from "../../components/custom-text";

export const Faq = () => {
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(FAQ_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="page-container flex flex-col">
      <div className="grid grid-cols-1 theme-content-container">
        <img
          className="w-full mt-0 justify-self-center"
          src={"/faq.jpg"}
          alt=""
        />
        {contentfulContent?.customHeader1?.line1 && (
          <CustomHeader
            className="theme-header-3 pt-4"
            contentfulCustomText={contentfulContent.customHeader1}
          />
        )}
        {contentfulContent?.customHeader2?.line1 && (
          <CustomHeader
            className="theme-header-3-small pt-4"
            contentfulCustomText={contentfulContent.customHeader2}
          />
        )}
        {contentfulContent?.customText1?.line1 && (
          <CustomText
            className="theme-text-3-small pb-4"
            contentfulCustomText={contentfulContent.customText1}
          />
        )}
        {contentfulContent?.customHeader3?.line1 && (
          <CustomHeader
            className="theme-header-3-small pt-4"
            contentfulCustomText={contentfulContent.customHeader3}
          />
        )}
        {contentfulContent?.customText2?.line1 && (
          <CustomText
            className="theme-text-3-small pb-4"
            contentfulCustomText={contentfulContent.customText2}
          />
        )}
        {contentfulContent?.customHeader4?.line1 && (
          <CustomHeader
            className="theme-header-3-small pt-4"
            contentfulCustomText={contentfulContent.customHeader4}
          />
        )}
        {contentfulContent?.customText3?.line1 && (
          <CustomText
            className="theme-text-3-small pb-4"
            contentfulCustomText={contentfulContent.customText3}
          />
        )}
        {contentfulContent?.customHeader5?.line1 && (
          <CustomHeader
            className="theme-header-3-small pt-4"
            contentfulCustomText={contentfulContent.customHeader5}
          />
        )}
        {contentfulContent?.customText4?.line1 && (
          <CustomText
            className="theme-text-3-small pb-4"
            contentfulCustomText={contentfulContent.customText4}
          />
        )}
        {contentfulContent?.customHeader6?.line1 && (
          <CustomHeader
            className="theme-header-3-small pt-4"
            contentfulCustomText={contentfulContent.customHeader6}
          />
        )}
        {contentfulContent?.customText5?.line1 && (
          <CustomText
            className="theme-text-3-small pb-4"
            contentfulCustomText={contentfulContent.customText5}
          />
        )}
        {contentfulContent?.customHeader7?.line1 && (
          <CustomHeader
            className="theme-header-3-small pt-4"
            contentfulCustomText={contentfulContent.customHeader7}
          />
        )}
        {contentfulContent?.customText6?.line1 && (
          <CustomText
            className="theme-text-3-small pb-4"
            contentfulCustomText={contentfulContent.customText6}
          />
        )}
        {contentfulContent?.customHeader8?.line1 && (
          <CustomHeader
            className="theme-header-3-small pt-4"
            contentfulCustomText={contentfulContent.customHeader8}
          />
        )}
        {contentfulContent?.customText7?.line1 && (
          <CustomText
            className="theme-text-3-small pb-4"
            contentfulCustomText={contentfulContent.customText7}
          />
        )}
        {contentfulContent?.customHeader9?.line1 && (
          <CustomHeader
            className="theme-header-3-small pt-4"
            contentfulCustomText={contentfulContent.customHeader9}
          />
        )}
        {contentfulContent?.customText8?.line1 && (
          <CustomText
            className="theme-text-3-small pb-4"
            contentfulCustomText={contentfulContent.customText8}
          />
        )}
        {contentfulContent?.customHeader10?.line1 && (
          <CustomHeader
            className="theme-header-3-small pt-4"
            contentfulCustomText={contentfulContent.customHeader10}
          />
        )}
        {contentfulContent?.customText9?.line1 && (
          <CustomText
            className="theme-text-3-small pb-4"
            contentfulCustomText={contentfulContent.customText9}
          />
        )}
      </div>
    </div>
  );
};
