import type { AxiosInstance } from "axios";
import Configuration from "../configuration.json";
import {
  ContentfulGetEntryResponse,
  CONTENTFUL_GET_ENTRY_RESPONSE,
} from "./types/contentful-content";
import { APIErrorResponse, API_ERROR_RESPONSE } from "./types/api-error";

export const HOME_PAGE = Configuration.CONTENTFUL_PAGES.HOME;
export const GETTING_STARTED_PAGE = Configuration.CONTENTFUL_PAGES.GETTING_STARTED;
export const FAQ_PAGE = Configuration.CONTENTFUL_PAGES.FAQ;
export const CHILD_FORM_PAGE = Configuration.CONTENTFUL_PAGES.CHILD_FORM;
export const TEEN_FORM_PAGE = Configuration.CONTENTFUL_PAGES.TEEN_FORM;
export const ADULT_FORM_PAGE = Configuration.CONTENTFUL_PAGES.ADULT_FORM;

export const contentfulAPI = (api: AxiosInstance) => ({
  contentful: {
    getEntry: async (
      page: string
    ): Promise<ContentfulGetEntryResponse | APIErrorResponse> =>
      await api
        .get(
          "/spaces/" +
            process.env.REACT_APP_CONTENTFUL_SPACE +
            "/entries/" +
            page +
            "?access_token=" +
            process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
        )
        .then(
          ({ data }) => {
            return { data: data, type: CONTENTFUL_GET_ENTRY_RESPONSE };
          },
          ({ response }) => ({
            ...(response || { data: {} }).data,
            type: API_ERROR_RESPONSE,
          })
        ),
  },
});
