import { ContentfulCustomText } from "../api/types/contentful-content";

type props = {
  className?: string;
  contentfulCustomText?: ContentfulCustomText;
};

export const CustomText: React.FunctionComponent<props> = ({
  className,
  contentfulCustomText,
}) => {
  return (
    <div>
      {contentfulCustomText?.line1 && (
        <p
          className={
            contentfulCustomText?.alignment1 === "center"
              ? `${className} text-center`
              : contentfulCustomText?.alignment1 === "right"
              ? `${className} text-right`
              : contentfulCustomText?.alignment1 === "left"
              ? `${className} text-left`
              : `${className}`
          }
        >
          {contentfulCustomText?.line1}
        </p>
      )}
      {contentfulCustomText?.line2 && (
        <p
          className={
            contentfulCustomText?.alignment2 === "center"
              ? `${className} text-center`
              : contentfulCustomText?.alignment2 === "right"
              ? `${className} text-right`
              : contentfulCustomText?.alignment2 === "left"
              ? `${className} text-left`
              : `${className}`
          }
        >
          {contentfulCustomText?.line2}
        </p>
      )}
    </div>
  );
};
