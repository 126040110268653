import { useEffect, useState } from "react";
import { useApi } from "../../api";
import { ADULT_FORM_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";
import { CustomHeader } from "../../components/custom-header";
import { CustomText } from "../../components/custom-text";
import { Download } from "@styled-icons/boxicons-regular/Download";
import { Alert } from "../../components/alert";

export const AdultForm = () => {
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(ADULT_FORM_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const useScript = (url: string): any => {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, [url]);
  };

  const download = (confirmDownload: boolean) => {
    document.body.style.overflow = "visible";
    if (!confirmDownload) {
      setShowAlert(false);
      return;
    }
    const url: any = "../adult-form.PDF";
    const a = document.createElement("a");
    a.href = url;
    a.download = url.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setShowAlert(false);
  };

  return (
    <div className="page-container flex flex-col">
      {showAlert && (
        <Alert
          question="Do you want to download adult-form.pdf?"
          onClick={(confirmDownload: boolean) => download(confirmDownload)}
        />
      )}
      <div className="theme-content-container">
        <div className="container-col-2 align-items-center justify-left-forced">
          {contentfulContent?.customHeader1?.line1 && (
            <CustomHeader
              className="theme-header-3-small"
              contentfulCustomText={contentfulContent.customHeader1}
            />
          )}
          <Download
            onClick={() => {
              setShowAlert(true);
              document.body.style.overflow = "hidden";
            }}
            className="form-download"
          />
        </div>
        {contentfulContent?.customText1?.line1 && (
          <CustomText
            className="theme-text-3-small"
            contentfulCustomText={contentfulContent.customText1}
          />
        )}
        {contentfulContent?.customText2?.line1 && (
          <CustomText
            className="theme-text-3-small"
            contentfulCustomText={contentfulContent.customText2}
          />
        )}
        <iframe
          title="adult-form"
          className="paperform-adult-form"
          src="https://ae1bvkth.paperform.co/"
        >
          {useScript("https://paperform.co/__embed.min.js")}
        </iframe>
      </div>
    </div>
  );
};
