import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../api";
import { HOME_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";
import { CustomHeader } from "../../components/custom-header";
import { HomeContentBox } from "../../components/home-content-box";
import { BookInformation } from "@styled-icons/fluentui-system-filled/BookInformation";
import { Child } from "@styled-icons/fa-solid/Child";
import { PersonFill } from "@styled-icons/bootstrap/PersonFill";
import { PersonPraying } from "@styled-icons/fa-solid/PersonPraying";

export const Home = () => {
  const navigate = useNavigate();
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(HOME_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="no-padding-page-container flex flex-col">
      <div className="home-bg-image-container">
        <div className="page-break" />
        {contentfulContent?.customHeader1?.line1 && (
          <CustomHeader
            className="bg-image-container-header"
            contentfulCustomText={contentfulContent.customHeader1}
          />
        )}
        {contentfulContent?.customHeader2?.line1 && (
          <CustomHeader
            className="bg-image-container-sub-header"
            contentfulCustomText={contentfulContent.customHeader2}
          />
        )}
        <div className="home-content-container">
          {contentfulContent?.customHomeContentBox1 && (
            <div className="home-content-outer-container">
              <div className="home-content-inner-container">
                <BookInformation className="home-content-icon" />
                <HomeContentBox
                  contentfulContentBox={
                    contentfulContent?.customHomeContentBox1
                  }
                  onClick={() => navigate("/getting-started")}
                />
              </div>
            </div>
          )}
          {contentfulContent?.customHomeContentBox2 && (
            <div className="home-content-outer-container">
              <div className="home-content-inner-container">
                <PersonPraying className="home-content-icon" />
                <HomeContentBox
                  contentfulContentBox={
                    contentfulContent?.customHomeContentBox2
                  }
                  onClick={() => navigate("/forms-adult")}
                />
              </div>
            </div>
          )}
          {contentfulContent?.customHomeContentBox3 && (
            <div className="home-content-outer-container">
              <div className="home-content-inner-container">
                <PersonFill className="home-content-icon" />
                <HomeContentBox
                  contentfulContentBox={
                    contentfulContent?.customHomeContentBox3
                  }
                  onClick={() => navigate("/forms-teen")}
                />
              </div>
            </div>
          )}
          {contentfulContent?.customHomeContentBox4 && (
            <div className="home-content-outer-container">
              <div className="home-content-inner-container">
                <Child className="home-content-icon" />
                <HomeContentBox
                  contentfulContentBox={
                    contentfulContent?.customHomeContentBox4
                  }
                  onClick={() => navigate("/forms-child")}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
