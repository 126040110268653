import { Route, Routes } from "react-router-dom";
import { Footer } from "./components/footer";
import { Navbar } from "./components/navbar";
import { AdultForm } from "./pages/adult-form";
import { ChildForm } from "./pages/child-form";
import { Faq } from "./pages/faq";
import { GettingStarted } from "./pages/getting-started";
import { Home } from "./pages/home";
import { TeenForm } from "./pages/teen-form";

export const App = () => {
  return (
    <div className="min-h-screen theme-bg-color-5">
      <Navbar />
      <section className="w-full flex justify-center h-auto webkit-text-center">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/getting-started" element={<GettingStarted />} />
          <Route path="/forms-child" element={<ChildForm />} />
          <Route path="/forms-teen" element={<TeenForm />} />
          <Route path="/forms-adult" element={<AdultForm />} />
          <Route path="/faq" element={<Faq />} />
        </Routes>
      </section>
      <Footer />
    </div>
  );
};
