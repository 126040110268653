import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../api";
import { GETTING_STARTED_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";
import { CustomHeader } from "../../components/custom-header";
import { CustomText } from "../../components/custom-text";

export const GettingStarted = () => {
  const navigate = useNavigate();
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(GETTING_STARTED_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="no-padding-page-container flex flex-col">
      <div className="getting-started-bg-image-container">
        <div className="page-break" />
        {contentfulContent?.customHeader1?.line1 && (
          <CustomHeader
            className="bg-image-container-header"
            contentfulCustomText={contentfulContent.customHeader1}
          />
        )}
        {contentfulContent?.customHeader2?.line1 && (
          <CustomHeader
            className="bg-image-container-sub-header"
            contentfulCustomText={contentfulContent.customHeader2}
          />
        )}
        <div className="page-break" />
      </div>
      <div className="getting-started-content-container">
        {contentfulContent?.customHeader3?.line1 && (
          <CustomHeader
            className="theme-header-3"
            contentfulCustomText={contentfulContent.customHeader3}
          />
        )}
        {contentfulContent?.customText1?.line1 && (
          <CustomText
            className="theme-text-3"
            contentfulCustomText={contentfulContent.customText1}
          />
        )}
        <div className="container-col-2 flex-wrap-reverse mb-4">
          <div className="container-col-2-item align-self-center pt-4">
            <img
              className="w-full"
              alt="butterfly"
              src="/getting-started-butterfly.jpg"
            />
          </div>
          <div className="container-col-2-item pl-5 px-0-f">
            {contentfulContent?.customHeader4?.line1 && (
              <CustomHeader
                className="theme-header-3-small pt-4"
                contentfulCustomText={contentfulContent.customHeader4}
              />
            )}
            {contentfulContent?.customText2?.line1 && (
              <CustomText
                className="theme-text-3-small"
                contentfulCustomText={contentfulContent.customText2}
              />
            )}
          </div>
        </div>
        {/* <iframe
          id="myIframe"
          title="cover-letter"
          className="docx-cover-letter"
          src="CoverLetter.html"
        /> */}
        {contentfulContent?.customHeader5?.line1 && (
          <CustomHeader
            className="theme-header-3-small pt-4"
            contentfulCustomText={contentfulContent.customHeader5}
          />
        )}
        {contentfulContent?.customText3?.line1 && (
          <CustomText
            className="theme-text-3-small pb-4"
            contentfulCustomText={contentfulContent.customText3}
          />
        )}
        <div className="container-col-3">
          <button
            className="theme-btn-4 mb-6 mx-5"
            onClick={() => navigate("/forms-adult")}
          >
            Adult Forms
          </button>
          <button
            className="theme-btn-4 mb-6 mx-5"
            onClick={() => navigate("/forms-teen")}
          >
            Teen Forms
          </button>
          <button
            className="theme-btn-4 mb-6 mx-5"
            onClick={() => navigate("/forms-child")}
          >
            Child Forms
          </button>
        </div>
      </div>
    </div>
  );
};
