import { ContentfulCustomHomeContentBox } from "../api/types/contentful-content";

type props = {
  contentfulContentBox?: ContentfulCustomHomeContentBox;
  onClick: Function;
};

export const HomeContentBox: React.FunctionComponent<props> = ({
  contentfulContentBox,
  onClick,
}) => {
  return (
    <div>
      <h1 className="home-content-title">{contentfulContentBox?.header}</h1>
      <p className="home-content-paragraph">{contentfulContentBox?.text}</p>
      <button className="theme-btn-4 mb-6 mx-5" onClick={() => onClick()}>
        {contentfulContentBox?.buttonLabel}
      </button>
    </div>
  );
};
