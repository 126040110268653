type props = {
  question: string;
  onClick: Function;
};

export const Alert: React.FunctionComponent<props> = ({
  question,
  onClick,
}) => {
  return (
    <div className="theme-alert-backdrop">
      <div className="theme-alert">
        <button className="theme-alert-close" onClick={() => onClick(false)}>
          X
        </button>
        <h1 className="theme-text-3-small text-left mt-5-forced">{question}</h1>
        <button className="theme-btn-4 m-4" onClick={() => onClick(true)}>
          Download
        </button>
      </div>
    </div>
  );
};
